import './navbar.css';

export const NavBar = () => {
    return <nav>
        <ul>
            <li><a href="/">Главная страница</a></li>
            <li><a href="/tsars">Цари</a></li>
            <li><a href="/imperors">Императоры</a></li>
            <li><a href="/gensek">Руководители СССР</a></li>
            <li><a href="/creators">О создателях</a></li>
        </ul>
    </nav>
}
